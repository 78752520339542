import { defineCustomElement } from '../commons'
import { initWixElement } from '../wixElements'
import { wixBgMediaWrapper } from './wixBgMedia'

export const wixBgMediaElementName = 'wix-bg-media'

export const initWixBgMedia = (contextWindow = globalThis.window, externalServices = {}) => {
	if (!contextWindow) {
		return
	}
	if (contextWindow.customElements.get(wixBgMediaElementName) === undefined) {
		const WixElement = initWixElement(contextWindow)
		const WixBgMedia = wixBgMediaWrapper(WixElement, externalServices, contextWindow)
		defineCustomElement(contextWindow, wixBgMediaElementName, WixBgMedia)
	}
}
