import { wixVideoWrapper } from './wixVideo'
import { defineCustomElement } from '../commons'
import { initWixElement } from '../wixElements'

export const wixVideoElementName = 'wix-video'

export type CustomElementServices = {
	isExperimentOpen: (experimentName: string) => boolean
	mutationService?: any
}

export const initWixVideo = (
	contextWindow = globalThis.window,
	externalServices: CustomElementServices,
	environmentConsts = {
		experiments: {},
	}
) => {
	if (!contextWindow) {
		return
	}
	if (contextWindow.customElements.get(wixVideoElementName) === undefined) {
		const WixElement = initWixElement(contextWindow)
		let WixVideo
		if (externalServices?.isExperimentOpen('specs.thunderbolt.videoLazyLoading')) {
			const intersectionObserver = new IntersectionObserver(
				(entries: Array<IntersectionObserverEntry>) =>
					entries.map((entry) => {
						if (entry.isIntersecting) {
							const target = entry.target as any
							target.unobserveIntersect()
							// resize Observe will invoke relayout imidiatly.
							// "Observation will fire when observation starts if Element is being rendered, and Element’s size is not 0,0,"
							// https://drafts.csswg.org/resize-observer/#dom-resizeobserver-observe
							target.observeResize()
						}
						return entry
					}),
				{
					rootMargin: '50% 100%',
				}
			)
			WixVideo = wixVideoWrapper(WixElement, { ...externalServices, intersectionObserver }, environmentConsts)
		} else {
			WixVideo = wixVideoWrapper(WixElement, externalServices, environmentConsts)
		}
		defineCustomElement(contextWindow, wixVideoElementName, WixVideo)
	}
}
